import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`What is the value of life?`}<br parentName="p"></br>{`
`}{`When it can be ended so quickly,`}<br parentName="p"></br>{`
`}{`Yet it has the power to change the world.`}<br parentName="p"></br>{`
`}{`With a gun’s clap shooting a bullet with all his future`}<br parentName="p"></br>{`
`}{`Everything goes black,`}<br parentName="p"></br>{`
`}{`And with a pull of the trigger,`}<br parentName="p"></br>{`
`}{`The man is no more.`}<br parentName="p"></br>{`
`}{`What is the value of life?`}</p>
    <p>{`Every day he comes home from school with a sigh of relief,`}<br parentName="p"></br>{`
`}{`Not because school is over,`}<br parentName="p"></br>{`
`}{`But because he didn't die today.`}<br parentName="p"></br>{`
`}{`Yet there is someone committed to changing that,`}<br parentName="p"></br>{`
`}{`As they become the one who take away that sigh of relief.`}<br parentName="p"></br>{`
`}{`Because he won’t be coming home that day.`}<br parentName="p"></br>{`
`}{`Or the next day.`}<br parentName="p"></br>{`
`}{`Or the day after that.`}</p>
    <p>{`As bangs like fireworks rang through the building,`}<br parentName="p"></br>{`
`}{`Fear swallowed the campus whole.`}<br parentName="p"></br>{`
`}{`Every pop ending a life.`}<br parentName="p"></br>{`
`}{`And as those pops tormented the room,`}<br parentName="p"></br>{`
`}{`Butterflies filled her stomach with angst.`}<br parentName="p"></br>{`
`}{`Those butterflies armed with knives that stabbed`}<br parentName="p"></br>{`
`}{`And tore their way through from the inside,`}<br parentName="p"></br>{`
`}{`Meeting the bullet which pierced her from out.`}</p>
    <p>{`When do the people realize the importance of a life?`}<br parentName="p"></br>{`
`}{`When will the people stop taking it for granted?`}<br parentName="p"></br>{`
`}{`And come together to end the bloodshed.`}<br parentName="p"></br>{`
`}{`How do we make sure these massacres will cease to exist?`}<br parentName="p"></br>{`
`}{`Because what gives a man the right to own something designed to kill and only to kill`}<br parentName="p"></br>{`
`}{`When our children need to graduate without scars.`}<br parentName="p"></br>{`
`}{`Because only by making someone confront death face to face`}<br parentName="p"></br>{`
`}{`Will they truly understand the value of a life.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      